import { useEffect, useRef, ChangeEvent, useState } from 'react';
import { useStore } from '../../../store';
import yaml from 'js-yaml';
import { BackButton } from '../../back-button';
import MonacoEditor from '../../monaco-editor';
import SwaggerInterface from '../../swagger-interface';
import 'swagger-ui-react/swagger-ui.css';
import styles from './editor.module.scss';

type Props = {
  id: string;
};
function Editor({ id }: Props) {
  const accessToken = useStore(state => state.accessToken);
  const getDocument = useStore(state => state.getDocument); //Запрос на создание нового документа, при выполнении устанавливает newDocumentId
  const editedDoc = useStore(state => state.editedDoc);
  const updateDocument = useStore(state => state.updateDocument);
  const swaggerReRenderToggle = useStore(state => state.swaggerReRenderToggle);
  const getServicesData = useStore(state => state.getServicesData);

  const yamlSnapshot = useRef<string>(yaml.dump(editedDoc));
  const editorRef = useRef<any>(null);

  const handleKeyUp = (e: any) => {
    if (e.target && e.target.className === 'inputarea monaco-mouse-cursor-text') {
      handleEditorUpdate();
    }
  };
  let timeout: any;
  const handleEditorUpdate = () => {
    clearTimeout(timeout);
    timeout = setTimeout(onSave, 2000);
  };
  function onSave() {
    if (editorRef && editorRef.current) {
      const jsonDoc: any = yaml.load(editorRef.current.getValue());
      if (yaml.dump(jsonDoc) !== yamlSnapshot.current) {
        yamlSnapshot.current = yaml.dump(jsonDoc);
        updateDocument(
          {
            name: jsonDoc.info.title,
            version: jsonDoc.info.version,
            doc_json: jsonDoc,
          },
          id,
        );
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    getDocument(id);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
      getServicesData();
    };
  }, []);

  return (
    <section className={styles.container}>
      <BackButton />
      {editedDoc && (
        <>
          <h2>Редактирование API документации cервиса "{editedDoc.info.title}"</h2>
          <p className={styles.msg}>Данные сохраняются автоматически в процессе редактирования</p>
          <div className={styles.columns}>
            <div className={styles.column}>
              <MonacoEditor editorRef={editorRef} data={yaml.dump(editedDoc)} language='yaml' />
            </div>
            <div className={styles.column}>
              {true && (
                <SwaggerInterface
                  toggler={swaggerReRenderToggle}
                  url={`https://docs.sima.team/api/v1/docs/${String(id)}/json?token=${accessToken}`}
                />
              )}
            </div>
          </div>
        </>
      )}
    </section>
  );
}

export default Editor;
