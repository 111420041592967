import 'swagger-ui-react/swagger-ui.css';
import { ServiceData } from '../../../services/types/types';
import { useStore } from '../../../store';
import { BackButton } from '../../back-button';
import SwaggerInterface from '../../swagger-interface';
import styles from './viewer.module.scss';

type Props = {
  id: string;
};

function Viewer({ id }: Props) {
  const accessToken = useStore(state => state.accessToken);
  const title = useStore(
    state => state.servicesData?.find((data: ServiceData) => data.id === Number(id))?.name,
  );

  return (
    <section className={styles.container}>
      <div className={styles['back-button']}>
        <BackButton />
      </div>
      <h2>Просмотр API документации cервиса "{title}"</h2>
      <SwaggerInterface
        toggler={true}
        url={`https://docs.sima.team/api/v1/docs/${id}/json?token=${accessToken}`}
      />
    </section>
  );
}

export default Viewer;
