import { useParams } from 'react-router-dom';
import 'swagger-ui-react/swagger-ui.css';
import Viewer from '../../components/editor/viewer';
import Editor from '../../components/editor/editor';

function ReaderEditor() {
  let { serviceId } = useParams();
  const mode = String(serviceId?.match(/[a-z]+/)); //Достаю режим работы редактора
  const id = String(serviceId?.match(/\d+$/)); //Достаю id выбранного сервиса

  switch (String(mode)) {
    case 'edit':
      return <Editor id={id} />;
    case 'read':
      return <Viewer id={id} />;
    default:
      return null;
  }
}

export default ReaderEditor;
