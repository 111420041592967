import { Outlet } from 'react-router-dom';
import Header from '../header';
import styles from './layout.module.scss'

function Layout() {
  return (
    <div>
      <Header />
      <div className={styles.outlet}>
      <Outlet />
      </div>
    </div>
  );
}

export default Layout;
