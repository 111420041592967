import { useNavigate } from 'react-router-dom';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import cn from 'classnames';
import styles from './back-button.module.scss';

type Props = {
  className?: string;
};

export const BackButton = ({ className }: Props) => {
  const navigation = useNavigate();
  const goBack = () => {
    navigation('../');
  };
  return (
    <button
      className={cn(styles.wrapper, className)}
      onClick={goBack}
      title='назад к списку сервисов'
    >
      <img src={ArrowLeft} alt='' />
    </button>
  );
};
