import { TextField, TextFieldProps } from '@sima-land/ui-nucleons/text-field';
import { useState } from 'react';
import { ReactComponent as EyeClosed } from '../../../assets/icons/eye-closed.svg';
import { ReactComponent as EyeOpened } from '../../../assets/icons/eye-opened.svg';
import styles from './password-field.module.scss';
import { PasswordFieldTypes } from './password-field.types';

const PasswordField = ({ ...props }: TextFieldProps) => {
  const [type, setType] = useState<PasswordFieldTypes>(PasswordFieldTypes.PASSWORD);
  const isPassword = type === PasswordFieldTypes.PASSWORD;
  const toggleType = () => {
    setType(isPassword ? PasswordFieldTypes.TEXT : PasswordFieldTypes.PASSWORD);
  };

  const adornment = (
    <button type='button' onClick={toggleType} className={styles.button}>
      {isPassword ? <EyeClosed /> : <EyeOpened />}
    </button>
  );

  return <TextField type={type} endAdornment={adornment} {...props} />;
};

export default PasswordField;
