export const getDate = (dateData: string) => {
  const formattedDate = new Date(dateData);
  return String(
    `${formattedDate.getDate() < 10 ? '0' + formattedDate.getDate() : formattedDate.getDate()}.${
      formattedDate.getMonth() < 10
        ? '0' + Number(formattedDate.getMonth() + 1)
        : formattedDate.getMonth() + 1
    }.${formattedDate.getFullYear()} в 
    ${formattedDate.getHours() < 10 ? '0' + formattedDate.getHours() : formattedDate.getHours()}:${
      formattedDate.getMinutes() < 10
        ? '0' + formattedDate.getMinutes()
        : formattedDate.getMinutes()
    }`,
  );
};
