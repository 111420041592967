import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import style from './swagger-interface.module.scss';

type Props = {
  url: string;
  toggler: boolean;
};

function SwaggerInterface({ toggler, url }: Props) {
  return (
    <div className={style.container}>
      <SwaggerUI url={url + '&local-time=' + new Date()} />
    </div>
  );
}

export default SwaggerInterface;
