import { Button } from '@sima-land/ui-nucleons/button';
import { Link } from 'react-router-dom';
import exitIcon from '../../assets/icons/exit.svg';
import { useStore } from '../../store';
import styles from './header.module.scss';

function Header() {
  const logOut = useStore(state => state.logOut);

  const handleLogOut = () => {
    logOut();
  };

  return (
    <section className={styles.container}>
      <Link to='/'>
        <h1 className={styles.link}>API Docs</h1>
      </Link>
      <Button viewType='secondary' className={styles.button} onClick={handleLogOut}>
        <h3>Log-out</h3> <img src={exitIcon} className={styles.icon} />
      </Button>
    </section>
  );
}

export default Header;
