import { Text } from '@sima-land/ui-nucleons/text';
import type { ReactElement } from 'react';

import styles from './error-text.module.scss';
import { IErrorTextProps } from './error-text.types';

const ErrorText = ({ errorMsg, ...props }: IErrorTextProps): ReactElement => (
  <div className={styles['error-container']}>
    {errorMsg && (
      <Text weight={400} size={12} lineHeight={16} color='additional-red' {...props}>
        {errorMsg}
      </Text>
    )}
  </div>
);

export default ErrorText;
