import { useState } from 'react';
import PasswordField from '../common/password-field';
import { Text } from '@sima-land/ui-nucleons/text';
import { Button } from '@sima-land/ui-nucleons/button';
import styles from './login-form.module.scss';
import { useStore } from '../../store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ILoginFormFields } from './login-form.types';
import { MOBILE_PHONE_REGEX } from '../../utils/constants';
import TextFieldWithError from '../common/text-field-with-error';

const LoginForm = () => {
  const authorize = useStore(state => state.authorize);
  const authorizationError = useStore(state => state.authorizationError);

  const [usernameError, setUsernameError] = useState<string>('');

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ILoginFormFields>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const handleUsernameChange = (username: string) => {
    const isMatch = MOBILE_PHONE_REGEX.test(username);

    setUsernameError(
      isMatch
        ? 'В поле "имя пользователя" ожидается имя учетной записи LDAP, а не номер телефона.'
        : '',
    );

    return true;
  };

  const onSubmit: SubmitHandler<ILoginFormFields> = ({ username, password }) => {
    authorize({ username, password });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Text size={24} weight={600} lineHeight={28}>
        Авторизация
      </Text>
      <Controller
        name='username'
        control={control}
        rules={{
          validate: handleUsernameChange,
          required: true,
        }}
        render={({ field: { onChange } }) => (
          <TextFieldWithError
            label='Имя пользователя'
            autoFocus
            onChange={onChange}
            error={usernameError}
          />
        )}
      />
      <Controller
        name='password'
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <PasswordField className={styles['password-field']} label='Пароль' onChange={onChange} />
        )}
      />
      <Button disabled={!isValid}>Войти</Button>
      {authorizationError && (
        <Text size={12} lineHeight={16} color='additional-red' align='center'>
          {authorizationError}
        </Text>
      )}
    </form>
  );
};

export default LoginForm;
