import create from 'zustand';
import { devtools } from 'zustand/middleware';
import authorizationApi from '../services/api/authorization';
import docsApi from '../services/api/api-docs';
import { STATUSES } from '../services/types';
import base64url from 'base64url';
import { AuthRequestData, DocRequestData, ServiceData } from '../services/types/types';

interface Store {
  status: STATUSES;
  isLoggedIn: boolean;
  authorizationError: string; //для отрисовки ошибки при авторизации
  //----------------------------------------------------
  accessToken: string;
  refreshToken: string;
  employeeId: string;
  //----------------------------------------------------
  servicesData: ServiceData[] | null; //для отрисовки карточек сервисов
  authorize: (data: AuthRequestData) => void;
  swaggerReRenderToggle: boolean;
  editedDoc: any;
  getServicesData: () => void; //загрузка данных с бэка для отрисовки карточек сервисов
  createNewDocument: (token: string, data: DocRequestData) => void;
  getDocument: (id: string) => void;
  updateDocument: (data: DocRequestData, id: string) => void;
  //----------------------------------------------------
  newDocumentId: string;
  resetNewDocumentId: () => void;
  //----------------------------------------------------
  deleteDocument: (id: number) => void;
  //----------------------------------------------------
  logOut: () => void;
  //----------------------------------------------------
}

export const useStore = create<Store>()(
  devtools((set, get) => ({
    status: STATUSES.initial,
    servicesData: null,
    isLoggedIn: false,
    authorizationError: '',
    accessToken: '',
    refreshToken: '',
    employeeId: '',
    newDocumentId: '',
    swaggerReRenderToggle: false,
    editedDoc: null,

    logOut: () => {
      set({ accessToken: '' });
      set({ isLoggedIn: false });
      localStorage.removeItem('acess-token');
    },

    //Отправляет логин и пароль, получает и сохраняет токен
    authorize: async ({ username, password }: AuthRequestData) => {
      set({ status: STATUSES.fetching });
      set({ authorizationError: '' });
      try {
        const response = await authorizationApi.getAcessToken({ username, password });
        const id = String(
          base64url.decode(response.data.access_token).match(/employee_id":"(.*?)","/g),
        ).slice(14, -3);
        set({
          employeeId: id,
        });
        localStorage.setItem('employeeId', id);
        localStorage.setItem('acess-token', response.data.access_token);
        localStorage.setItem('refresh-token', response.data.refresh_token);
        set({ accessToken: response.data.access_token });
        set({ refreshToken: response.data.refresh_token });
        set({ isLoggedIn: true });
        set({ status: STATUSES.success });
      } catch (error: unknown) {
        set({ status: STATUSES.failure });
        set({ authorizationError: String(error) });
      }
    },

    //Загружает массив данных с описанием существующих сервисов
    getServicesData: async () => {
      set({ status: STATUSES.fetching });
      const localStorageToken = localStorage.getItem('acess-token');
      const stateToken = get().accessToken;
      try {
        if (!localStorageToken) throw new Error('no token in local storage!');
        const response = await docsApi.getDocs(stateToken ? stateToken : localStorageToken);
        set({ servicesData: await response.data });
        set({ status: STATUSES.success });
        if (!stateToken) {
          set({ accessToken: localStorageToken });
          set({ isLoggedIn: true });
          const id = localStorage.getItem('employeeId');
          if (id)
            set({
              employeeId: id,
            });
        }
      } catch (error: any) {
        set({ status: STATUSES.failure });
        console.error('error.message', error.message);
        if (error.message === 'Request failed with status code 400') {
          set({ accessToken: '' });
          set({ isLoggedIn: false });
          localStorage.removeItem('acess-token');
          //Пытаемся переполучить токены
          try {
            const authorizationRequestData = localStorage.getItem('refresh-token');
            if (authorizationRequestData) {
              const {
                data: { access_token: acessToken },
                data: { refresh_token: refreshToken },
              } = await authorizationApi.refreshAcessToken(authorizationRequestData);
              const id = String(
                base64url.decode(acessToken).match(/employee_id":"(.*?)","/g),
              ).slice(14, -3);
              set({
                employeeId: id,
              });
              localStorage.setItem('employeeId', id);
              localStorage.setItem('acess-token', acessToken);
              localStorage.setItem('refresh-token', refreshToken);
              set({ accessToken: acessToken });
              set({ refreshToken: refreshToken });
              set({ status: STATUSES.success });
              set({ authorizationError: 'sucess' }); //Костылёчек, заставляет ре-редерится LoginPage
            }
          } catch (error: unknown) {
            console.error(String(error));
            set({ status: STATUSES.failure });
            set({ authorizationError: String(error) });
          }
        }
      }
    },

    //Добавляет новый документ в список документов
    createNewDocument: async (token, data) => {
      set({ status: STATUSES.fetching });
      try {
        const response = await docsApi.postDocs(token, data);
        set({ status: STATUSES.success });
        set({ newDocumentId: String(response.data.id) });
      } catch (error: unknown) {
        set({ status: STATUSES.failure });
      }
    },

    getDocument: async id => {
      set({ status: STATUSES.fetching });
      try {
        const response = await docsApi.getDocJson(get().accessToken, id);
        set({ status: STATUSES.success });
        set({ editedDoc: response.data });
      } catch (error: unknown) {
        set({ status: STATUSES.failure });
      }
    },

    //Добавляет новый документ в список документов
    updateDocument: async (data, id) => {
      set({ status: STATUSES.fetching });
      try {
        await docsApi.putDoc(get().accessToken, id, data);
        set({ status: STATUSES.success });
        set({ swaggerReRenderToggle: !get().swaggerReRenderToggle });
      } catch (error: unknown) {
        set({ status: STATUSES.failure });
      }
    },

    resetNewDocumentId: () => {
      set({ newDocumentId: '' });
    },

    deleteDocument: async (id: number) => {
      set({ status: STATUSES.fetching });
      try {
        await docsApi.deleteDoc(get().accessToken, id);
        set({ status: STATUSES.success });
        const data = get().servicesData;
        if (data) {
          const deletingIndex = data.findIndex((data: ServiceData) => data.id === id);
          set({
            servicesData: [...data.slice(0, deletingIndex), ...data.slice(deletingIndex + 1)],
          });
        }
      } catch (error: unknown) {
        set({ status: STATUSES.failure });
      }
    },
    //TODO проверять, не равно ли значение кода предыдущему для оптимизации количества запросов
    //updateServicesData:
  })),
);
