import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from '../../router/router';
import Preloader from '../preloader';

function App() {
  useEffect(() => {
    document.title = 'API Docs';
  }, []);

  return (
    <Preloader>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Preloader>
  );
}

export default App;
