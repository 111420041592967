import Editor from '@monaco-editor/react';
import { Spinner } from '../preloader/spinner';
import styles from './monaco-editor.module.scss';

type Props = {
  data: string;
  language: string;
  editorRef: any;
};

function MonacoEditor({ editorRef, data, language }: Props) {
  function handleEditorDidMount(editor: any, monaco: any) {
    editorRef.current = editor;
  }

  return (
    <div className={styles.container}>
      <div className={styles.editor}>
        <Editor
          onMount={handleEditorDidMount}
          theme='vs-dark'
          language={language}
          loading={<Spinner />}
          value={data}
        />
      </div>
    </div>
  );
}

export default MonacoEditor;
