export const initialDocumentData = {
  swagger: '2.0',
  info: {
    description: '',
    version: '',
    title: '',
    termsOfService: 'http://swagger.io/terms/',
    contact: {
      email: 'apiteam@swagger.io',
    },
    license: {
      name: 'Apache 2.0',
      url: 'http://www.apache.org/licenses/LICENSE-2.0.html',
    },
  },
};
