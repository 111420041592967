import { useEffect, useRef, useState } from 'react';
import { useStore } from '../../store';
import Search from '../../components/search';
import ServiceCard from '../../components/service-card';
import ServiceCardAdder from '../../components/service-card-adder';
import arrowUp from '../../assets/icons/arrow-up.svg';
import arrowDown from '../../assets/icons/arrow-down.svg';
import minus from '../../assets/icons/minus.svg';
import styles from './services.module.scss';
import { Button } from '@sima-land/ui-nucleons/button';
import { ServiceData } from '../../services/types/types';

function Services() {
  const servicesData = useStore(state => state.servicesData);
  const getServicesData = useStore(state => state.getServicesData);
  const [searchedData, setSearchedData] = useState<any[]>([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const employeeId = useStore(state => state.employeeId);

  const nameSortState = useRef<string>('none');
  const dateSortState = useRef<string>('none');

  useEffect(() => {
    if (!servicesData) {
      getServicesData();
    }
    if (servicesData) {
      const name = localStorage.getItem('name');
      if (name) {
        nameSortState.current = name;
        sortServicesData('name', name);
      }
      const date = localStorage.getItem('date');
      if (date) {
        dateSortState.current = date;
        const sortState = localStorage.getItem('date');
        sortState && sortServicesData('date', sortState);
      }
    }
  }, [servicesData]);

  const sortServicesData = (param: string, value: string) => {
    localStorage.removeItem('name');
    localStorage.removeItem('date');
    localStorage.setItem(param, value);

    if (servicesData) {
      if (value === 'none') {
        setSearchedData([...servicesData]);
        return;
      }
      const sortedData = [...servicesData].sort((a: ServiceData, b: ServiceData) => {
        if (param === 'date') {
          //Установка в session storage
          switch (value) {
            case 'incr':
              return Number(new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime());
              break;
            case 'decr':
              return Number(-(new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()));
              break;
            case 'none':
              return 0;
              break;
            default:
              return 0;
              break;
          }
        } else {
          switch (value) {
            case 'incr':
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              return 0;
              break;
            case 'decr':
              if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
              if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
              return 0;
              break;
            case 'none':
              return 0;
              break;
            default:
              return 0;
              break;
          }
        }
      });
      setSearchedData([...sortedData]);
      setIsInitialLoading(false);
    }
  };

  //Изменяем состояние сортировки
  const changeSortState = (e: any) => {
    if (e.target.id === 'byName') {
      dateSortState.current = 'none';
      switch (nameSortState.current) {
        case 'incr':
          nameSortState.current = 'decr';
          break;
        case 'decr':
          nameSortState.current = 'none';
          break;
        case 'none':
          nameSortState.current = 'incr';
          break;
        default:
          break;
      }
      sortServicesData('name', nameSortState.current);
    } else {
      nameSortState.current = 'none';
      switch (dateSortState.current) {
        case 'incr':
          dateSortState.current = 'decr';
          break;
        case 'decr':
          dateSortState.current = 'none';
          break;
        case 'none':
          dateSortState.current = 'incr';
          break;
        default:
          break;
      }
      sortServicesData('date', dateSortState.current);
    }
  };

  return (
    <section className={styles.container}>
      <h2>Список сервисов</h2>
      {servicesData && (
        <Search
          data={servicesData}
          setData={setSearchedData}
          setInitialFlag={setIsInitialLoading}
        />
      )}

      <div className={styles['sort-buttons']}>
        <Button id='byName' onClick={changeSortState}>
          {'По названию '}
          {nameSortState.current === 'incr' && <img src={arrowUp} className={styles.icon} />}
          {nameSortState.current === 'decr' && <img src={arrowDown} className={styles.icon} />}
          {nameSortState.current === 'none' && <img src={minus} className={styles.icon} />}
        </Button>
        <Button id='byDate' onClick={changeSortState}>
          По дате
          {dateSortState.current === 'incr' && <img src={arrowUp} className={styles.icon} />}
          {dateSortState.current === 'decr' && <img src={arrowDown} className={styles.icon} />}
          {dateSortState.current === 'none' && <img src={minus} className={styles.icon} />}
        </Button>
      </div>
      {!servicesData || (
        <ul className={styles.services}>
          {(isInitialLoading ? servicesData : searchedData).map(
            ({ name, version, id, updated_at, created_by }) => (
              <ServiceCard
                name={name}
                version={version}
                serviceId={id}
                key={id}
                updatedAt={updated_at}
                isDeletable={created_by == employeeId}
              />
            ),
          )}
        </ul>
      )}
      <div className={styles.adder}>
        <ServiceCardAdder />
      </div>
    </section>
  );
}

export default Services;
