import styles from './modal-search.module.scss';
import search from '../../assets/icons/search.svg';
import { TextField } from '@sima-land/ui-nucleons/text-field';
import { ServiceData } from '../../services/types/types';

type Props = {
  data: ServiceData[];
  setData: (data: ServiceData[]) => void;
  setInitialFlag?: (data: boolean) => void;
};

const Search = ({ data, setData, setInitialFlag }: Props) => {
  const handleChange = ({ target: { value } }: any) => {
    if (value) {
      setInitialFlag && setInitialFlag(false);
      setData(
        data.filter((data: ServiceData) => data.name.toLowerCase().includes(value.toLowerCase())),
      );
    } else {
      setData(data);
    }
  };

  const adornment = (
    <p className={styles.adornment}>
      <img src={search} />
    </p>
  );

  return (
    <TextField
      label='Введите для фильтрации значений'
      type='text'
      name='search'
      endAdornment={adornment}
      onChange={handleChange}
      className={styles.search}
    />
  );
};

export default Search;
