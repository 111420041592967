import { Button } from '@sima-land/ui-nucleons/button';
import { TextField } from '@sima-land/ui-nucleons/text-field';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { initialDocumentData } from '../../services/mock/mock';
import { useStore } from '../../store';
import styles from './service-card.module.scss';

const ServiceCardAdder = () => {
  const [isTitleError, setIsTitleError] = useState(false);
  const [isVersionError, setIsVersionError] = useState(false);
  const [isDescriptionError, setIsDescriptionError] = useState(false);
  const accessToken = useStore(state => state.accessToken);
  const newDocumentId = useStore(state => state.newDocumentId);
  const resetNewDocumentId = useStore(state => state.resetNewDocumentId);
  const createNewDocument = useStore(state => state.createNewDocument);

  useEffect(() => {
    if (newDocumentId) {
      resetNewDocumentId();
    }
  }, [newDocumentId]);

  let newApiInfo = useRef({
    name: initialDocumentData.info.title,
    version: initialDocumentData.info.version,
    description: initialDocumentData.info.description,
  });

  const handleInputChange = (e: any) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    newApiInfo.current = { ...newApiInfo.current, [name]: value };
  };

  const handleDocumentCreation = (e: FormEvent) => {
    e.preventDefault();
    if (
      newApiInfo.current.name === initialDocumentData.info.title ||
      newApiInfo.current.version === initialDocumentData.info.version ||
      newApiInfo.current.description === initialDocumentData.info.description
    ) {
      if (newApiInfo.current.name === initialDocumentData.info.title) {
        setIsTitleError(true);
      } else {
        setIsTitleError(false);
      }
      if (newApiInfo.current.version === initialDocumentData.info.version) {
        setIsVersionError(true);
      } else {
        setIsVersionError(false);
      }
      if (newApiInfo.current.description === initialDocumentData.info.description) {
        setIsDescriptionError(true);
      } else {
        setIsDescriptionError(false);
      }
      return;
    }

    createNewDocument(accessToken, {
      ...initialDocumentData,
      name: newApiInfo.current.name,
      version: newApiInfo.current.version,
      doc_json: {
        ...initialDocumentData,
        info: {
          ...initialDocumentData.info,
          title: newApiInfo.current.name,
          version: newApiInfo.current.version,
          description: newApiInfo.current.description,
        },
      },
    });
  };

  if (newDocumentId) {
    return <Navigate to={`edit-${newDocumentId}`} />;
  } else
    return (
      <section className={styles.container}>
        <TextField
          name='name'
          label='Название сервиса'
          defaultValue={initialDocumentData.info.title}
          autoFocus
          className={styles.input}
          onChange={handleInputChange}
          disabled={Boolean(newDocumentId)}
          failed={isTitleError}
        />
        <TextField
          name='version'
          label='Версия сервиса'
          defaultValue={initialDocumentData.info.version}
          className={styles.input}
          onChange={handleInputChange}
          disabled={Boolean(newDocumentId)}
          failed={isVersionError}
        />
        <TextField
          name='description'
          label='Описание сервиса'
          defaultValue={initialDocumentData.info.description}
          className={styles.input}
          onChange={handleInputChange}
          disabled={Boolean(newDocumentId)}
          failed={isDescriptionError}
        />
        <div className={styles['button-container']}>
          <Button className={styles['add-button']} onClick={handleDocumentCreation}>
            Добавить API для сервиса
          </Button>
        </div>
      </section>
    );
};

export default ServiceCardAdder;
