import { Button } from '@sima-land/ui-nucleons/button';
import { Alert } from '@sima-land/ui-nucleons/alert';
import { Link } from 'react-router-dom';
import { getDate } from './helpers';
import readIcon from '../../assets/icons/read.svg';
import editIcon from '../../assets/icons/edit.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import styles from './service-card.module.scss';
import { useStore } from '../../store';
import { useState } from 'react';

type Props = {
  name: string;
  version: string;
  serviceId: number;
  updatedAt: string;
  isDeletable: boolean;
  href?: string;
};

const ServiceCard = ({ name, version, serviceId, updatedAt, isDeletable }: Props) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const deleteDocument = useStore(state => state.deleteDocument);

  const toggleDeleteConfirmation = () => {
    setIsModalOpened(!isModalOpened);
  };

  const confirmDeletion = () => {
    deleteDocument(serviceId);
  };

  return (
    <>
      {isModalOpened && (
        <Alert onClose={toggleDeleteConfirmation}>
          <div className={styles['alert-content']}>
            <h3>{`Вы действительно хотите удалить документацию к сервису ${name}?`}</h3>
            <div className={styles.buttons}>
              <Button onClick={confirmDeletion} className={`${isDeletable && styles.button}`}>
                Удалить
              </Button>
              <Button onClick={toggleDeleteConfirmation}>Отмена</Button>
            </div>
          </div>
        </Alert>
      )}
      <section className={styles.container}>
        <div>
          <h3>{`${name}, v: ${version}`}</h3>
          <p>{'Изменено ' + getDate(updatedAt)}</p>
        </div>
        <div className={styles['button-container']}>
          <Link to={`read-${serviceId}`}>
            <Button>
              <img src={readIcon} />
            </Button>
          </Link>
          <Link to={`edit-${serviceId}`}>
            <Button>
              <img src={editIcon} />
            </Button>
          </Link>
          <div>
            <Button
              disabled={!isDeletable}
              onClick={toggleDeleteConfirmation}
              className={`${isDeletable && styles.button}`}
            >
              <img src={deleteIcon} />
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceCard;
