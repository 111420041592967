import { TextField } from '@sima-land/ui-nucleons/text-field';

import ErrorText from './error-text';
import styles from './text-field-with.error.module.scss';
import { ITextFieldWithErrorProps } from './text-field-with-error.types';

const TextFieldWithError = ({ error = '', ...props }: ITextFieldWithErrorProps) => (
  <div className={styles['text-field-container']}>
    <TextField {...props} />
    <ErrorText errorMsg={error} />
  </div>
);

export default TextFieldWithError;
