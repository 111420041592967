import { useLayoutEffect } from 'react';
import LoginForm from '../../components/login-form';
import { useStore } from '../../store';
import styles from './login.module.scss';

const Login = () => {
  const isLoggedIn = useStore(state => state.isLoggedIn);

  const servicesData = useStore(state => state.servicesData);
  const getServicesData = useStore(state => state.getServicesData);

  useLayoutEffect(() => {
    getServicesData();
  }, []);

  if (isLoggedIn) {
    if (!servicesData) {
      getServicesData();
    }
  }

  return (
    <section className={styles.container}>
      <LoginForm />
    </section>
  );
};

export default Login;
