import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Layout from '../components/layout';
import Services from '../pages/services';
import ReaderEditor from '../pages/editor';
import Login from '../pages/login';
import { useStore } from '../store';
import { useEffect, useState } from 'react';

type BackProps = { isRedirected: boolean; isLoggedIn: boolean };

function RedirectBack({ isRedirected, isLoggedIn }: BackProps) {
  let navigate = useNavigate();
  useEffect(() => {
    isRedirected ? navigate(-1) : navigate('/');
  }, [isLoggedIn]);
  return null;
}

type LoginProps = { isRedirected: boolean; setIsRedirected: (isRedirected: boolean) => void };

function RedirectToLogin({ isRedirected, setIsRedirected }: LoginProps) {
  useEffect(() => {
    if (!isRedirected) {
      setIsRedirected(true);
    }
  }, []);
  return <Navigate to='/login' />;
}

function Router() {
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const [isRedirected, setIsRedirected] = useState(false);
  return (
    <Routes>
      <Route
        path='/'
        element={
          isLoggedIn ? (
            <Layout />
          ) : (
            <RedirectToLogin setIsRedirected={setIsRedirected} isRedirected={isRedirected} />
          )
        }
      >
        <Route index element={<Services />} />
        <Route path=':serviceId' element={<ReaderEditor />} />
      </Route>
      <Route
        path={'/login'}
        element={
          isLoggedIn ? (
            <RedirectBack isRedirected={isRedirected} isLoggedIn={isLoggedIn} />
          ) : (
            <Login />
          )
        }
      />
      <Route path={'/404'} element={<p>Cтраница 404</p>} />
      <Route path='*' element={<Navigate replace to='/404' />} />
    </Routes>
  );
}

export default Router;
